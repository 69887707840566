import React from "react";
import ImageUploading from "react-images-uploading";
import apiClient from "../services/apiClient";
import { toast } from "react-toastify";

export function Upload({ values, setValues, setIsLoading }) {
  const maxNumber = 69;

  const [images, setImages] = React.useState(values?.img || []);

  const uploadImg = async (imgBase64) => {
    try {
      setIsLoading(true);
      const res = await apiClient.post(
        "/api/user-mind-map/upload",
        {
          extension: "png",
          data: imgBase64,
          folder: "user",
        },
        {
          headers: {
            uuid: "6e822e7b-e672-4bff-97b9-1480ed118453",
            user_id: 1,
          },
        }
      );
      setValues({ ...values, img: [{ data_url: res?.data?.data }] });
      setIsLoading(false);
      return res?.data?.data;
    } catch (error) {
      setIsLoading(false);
      toast.error("Lỗi");
    }
  };

  const onChange = (imageList) => {
    uploadImg(imageList[0]?.data_url);
    setImages(imageList);
  };

  return (
    <ImageUploading
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({ imageList, onImageUpload, isDragging, dragProps }) => (
        <div className="upload__image-wrapper">
          {!imageList?.length ? (
            <button
              style={isDragging ? { color: "#4DAC62" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Click or Drop here
            </button>
          ) : null}

          {imageList.map((image, index) => (
            <div
              key={index}
              className="image-item"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                key={index}
                className="image-item"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: 150,
                  height: 150,
                }}
                onClick={onImageUpload}
              >
                <img
                  src={image["data_url"]}
                  alt=""
                  style={{ width: "100%", objectFit: "cover" }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </ImageUploading>
  );
}
