import React from "react";
import Modal from "react-modal";
import apiClient from "../services/apiClient";
import Breadcrumb from "./Breadcrumb";
import { IoIosArrowBack } from "react-icons/io";

export default function ModalFolder({
  isOpen,
  setIsOpen,
  data,
  choseImageLib,
  setFolder,
}) {
  const [curentPage, setCurrentPage] = React.useState({
    id: 0,
    name: "Thư viện ảnh",
  });

  const onOpenFolder = async (fd) => {
    const res = await apiClient.post(
      "/api/folders/list",
      {
        type: "1",
        id: fd?.id || "",
      },
      {
        headers: {
          user_id: "1",
        },
      }
    );

    if (res?.data?.data) {
      setCurrentPage({
        id: fd?.parent_id,
        name: fd?.name,
      });
      setFolder(res?.data?.data);
    }
  };


  console.log(curentPage);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={{
        content: {
          height: "70vh",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: '#4DAC62',
          borderRadius: 10,
          borderWidth: 1,
          borderColor: '#fff',
        }}
      >
        <p onClick={() => onOpenFolder(curentPage?.id)} style={{paddingLeft: 10}}><IoIosArrowBack  /></p>
        <p>{curentPage?.name ?? 'Thư viện ảnh'}</p>
        <p></p>
      </div>
      <div
        style={{
          display: "flex",
          "justify-content": "space-between",
          background: "white",
          flexWrap: "wrap",
        }}
      >
        {(data || [])?.map((item, index) => {
          if (item?.folders?.length)
            return item.folders.map((i) => (
              <div
                onClick={() => {
                  onOpenFolder(i);
                }}
                key={index}
                style={{
                  width: "100%",
                  margin: 5,
                  border: "1px solid #DEDEDE",
                  background: "white",
                  cursor: "pointer",
                  color: "black",
                  borderRadius: 4,
                }}
              >
                <p style={{ textAlign: "center", padding: 5 }}>{i?.name}</p>
              </div>
            ));

          if (item?.images?.length)
            return item.images.map((i) => (
              <div
                onClick={() => {
                  choseImageLib(i);
                }}
                key={index}
                style={{
                  width: 120,
                  margin: 5,
                  border: "1px solid black",
                  background: "white",
                  cursor: "pointer",
                  color: "white",
                  borderRadius: 4,
                  padding: 3,
                  color: "black",
                  fontSize: 12,
                }}
              >
                <img
                  src={i?.image_url}
                  width={120}
                  height={120}
                  style={{ objectFit: "cover" }}
                />
                <p>{i?.file_name}</p>
              </div>
            ));
        })}
      </div>
    </Modal>
  );
}
