import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import apiClient from "../services/apiClient";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        loader.file.then(async (file) => {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function async() {
            apiClient
              .post(
                "/api/user-mind-map/upload",
                {
                  extension: "png",
                  data: reader.result,
                  folder: "user",
                },
                {
                  headers: {
                    uuid: "6e822e7b-e672-4bff-97b9-1480ed118453",
                    user_id: 1,
                  },
                }
              )
              .then((res) => resolve({ default: res?.data?.data }))
              .catch((er) => console.log(er));
          };
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };

          //   body.append("file", file);
          //   uploadFilePost(body)
          //     .then((res) => {
          //       resolve({
          //         default: res?.data,
          //       });
          //     })
          //     .catch((err) => {
          //       reject(err?.message);
          //     });
        });
      });
    },
  };
}
function uploadPlugin(editor) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
    return uploadAdapter(loader);
  };
}
const configCK = {
  extraPlugins: [uploadPlugin],
  toolbar: [
    "undo",
    "redo",
    "heading",
    "fontColor",
    "fontBackgroundColor",
    "strikethrough",
    "strikethrough",
    "underline",
    "italic",
    "bold",
    "link",
    "|",
    "outdent",
    "indent",
    "|",
    "uploadImage",
    "fontFamily",
    "fontSize",
    "|",
    "alignment",
    "numberedList",
    "bulletedList",
    "mediaEmbed",
    "insertTable",
  ],
  fontFamily: {
    options: [
      "default",
      "Arial, Helvetica, sans-serif",
      "Courier New, Courier, monospace",
      "Georgia, serif",
      "Lucida Sans Unicode, Lucida Grande, sans-serif",
      "Tahoma, Geneva, sans-serif",
      "Times New Roman, Times, serif",
      "Trebuchet MS, Helvetica, sans-serif",
      "Verdana, Geneva, sans-serif",
    ],
    supportAllValues: true,
  },
  fontSize: {
    options: [9, 11, 13, "default", 17, 19, 21],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    tableProperties: {
      // The default styles for tables in the editor.
      // They should be synchronized with the content styles.
      defaultProperties: {
        borderStyle: "dashed",
        borderColor: "hsl(90, 75%, 60%)",
        borderWidth: "3px",
        alignment: "left",
        width: "550px",
        height: "450px",
      },
      // The default styles for table cells in the editor.
      // They should be synchronized with the content styles.
      tableCellProperties: {
        defaultProperties: {
          horizontalAlignment: "center",
          verticalAlignment: "bottom",
          padding: "10px",
        },
      },
    },
  },
};
export default function Note({ disabled }) {
  const [editorCK, setEditorCK] = useState();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(`<p style="text-align:center;">Class:…….. Title:……… Date: …….</p><figure class="table"><table><tbody><tr><td><p style="text-align:center;">Cues</p></td><td><p style="text-align:center;">Note</p></td></tr><tr><td>ssssss</td><td>ssssss</td></tr><tr><td colspan="2">&nbsp;</td></tr></tbody></table></figure>`);
  const userId = queryParameters.get("userId");
  const id = queryParameters.get("id");
  const uuid = queryParameters.get("uuid");

  console.log(data);
  const saveNote = async () => {
    if (uuid && id) {
      const res = await apiClient.post(
        "/api/user-notes/update",
        {
          note: data,
          id: id,
        },
        {
          headers: {
            uuid: uuid, // "7213ec7f-26a6-4271-9916-30ac6c142a98",
            user_id: userId, //1,
          },
        }
      );

      if (res?.data) {
        toast.success("Cập nhật thành công");
        return;
      }
      toast.error("Lỗi");
      return;
    }
    const res = await apiClient.post(
      "/api/user-notes/create",
      {
        note: data,
        id: id,
      },
      {
        headers: {
          user_id: userId,
        },
      }
    );

    if (res?.data) {
      toast.success("Cập nhật thành công");
      return;
    }
    toast.error("Lỗi");
    return;
  };

  const getNoteDetail = async () => {
    try {
      const rest = await apiClient.post("/api/user-notes/detail", {
        uuid: uuid || "",
      });
      const data = rest?.data?.data;
      console.log(data);
      if (data?.note) {
        setData(data?.note);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (uuid) {
      getNoteDetail();
    }
  }, [uuid]);

  return (
    <div className="">
      <div
        style={{
          background: "#4DAC62",
          width: "100px",
          color: "white",
          padding: 5,
          borderRadius: 6,
          textAlign: "center",
          margin: "10px 0",
        }}
        onClick={saveNote}
      >
        Cập nhật
      </div>

      <CKEditor
        disabled={disabled}
        editor={DecoupledEditor}
        onReady={(editor) => {
          // Insert the toolbar before the editable area.
          editor.ui
            .getEditableElement()
            .parentElement.insertBefore(
              editor?.ui?.view?.toolbar?.element,
              editor?.ui?.getEditableElement()
            );
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "height",
              "600px",
              editor.editing.view.document.getRoot()
            );
          });
          setEditorCK(editor);
        }}
        onError={(error, { willEditorRestart }) => {
          // If the editor is restarted, the toolbar element will be created once again.
          // The `onReady` callback will be called again and the new toolbar will be added.
          // This is why you need to remove the older toolbar.
          if (willEditorRestart) {
            editorCK.ui.view.toolbar.element.remove();
          }
        }}
        onChange={(event, editor) => {
          setData(editor.getData());
          // field.onChange(editor.getData());
          // onChange && onChange(editor);
        }}
        data={data}
        config={configCK}
      />
    </div>
  );
}
