import React from "react";

export default function ItemTypeText({ name, isActive = false, onClick, styleCustom }) {
  return (
    <div
      style={{
        height: 20,
        textAlign: "center",
        background: isActive ? "#4DAC62" : "white",
        width: "100%",
        padding: 5,
        borderRadius: 5,
        margin: "0 4px",
        color: isActive ? "white" : "black",
        border: isActive ? "1px solid #4DAC62" : "1px solid #DEDEDE",
        ...styleCustom
      }}
      onClick={onClick}
    >
      {name}
    </div>
  );
}
