import React, { useState } from "react";

import { AiOutlineDelete, AiOutlineSwap } from "react-icons/ai";
import Sheet from "react-modal-sheet";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from "reactflow";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) {
  const { setEdges, edges } = useReactFlow();
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [isOpen, setOpen] = useState(false);

  const onEdgeClick = () => {
    setEdges((edges) => edges.filter((edge) => edge.id !== id));
  };

  const onEdgeChangeLine = (type) => {
    setEdges((edges) =>
      edges.map((edge) => {
        if (edge.id === id) {
          edge.animated = type;
        }
        return edge;
      })
    );
  };
  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: "all",
          }}
          className="nodrag nopan"
        >
          <button className="edgebutton" onClick={() => setOpen(true)}>
            {/* {data?.numbericIndex} */}
            <AiOutlineSwap />
          </button>
        </div>

        <Sheet
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          detent="content-height"
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "100%" }}>Nét đứt: </div>
                <div
                  style={{
                    cursor: "pointer",
                    width: "100%",
                  }}
                  onClick={() => onEdgeChangeLine(true)}
                >
                  <div style={{ borderBottom: "2px dashed black" }} />
                </div>
              </div>

              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={() => onEdgeChangeLine(false)}
              >
                <div style={{ width: "100%" }}>Nét liền: </div>
                <div
                  style={{ borderBottom: "1px solid black", width: "100%" }}
                />
              </div>

              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={onEdgeClick}
              >
                <div style={{ width: "100%" }}>Xóa: </div>

                <div style={{ cursor: "pointer", width: "100%" }}>
                  <AiOutlineDelete color="red" />
                </div>
              </div>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      </EdgeLabelRenderer>
    </>
  );
}
