import React from "react";

export default function ItemType({ icon, name, isActive = false, onClick }) {
  return (
    <div
      style={{
        height: 20,
        textAlign: "center",
        background: isActive ? "#4DAC62" : "white",
        width: "100%",
        padding: "5px",
        margin: "0 4px",
        borderRadius: 5,
        border: isActive ? "1px solid #4DAC62" : "1px solid #DEDEDE",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: isActive ? "white" : "black",
        }}
      >
        {icon()}
        {name}
      </div>
    </div>
  );
}
