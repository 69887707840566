import CircularJSON from "circular-json";
import { useCallback, useEffect, useState } from "react";
import {
  AiOutlineAlignCenter,
  AiOutlineAlignLeft,
  AiOutlineAlignRight,
  AiOutlineDelete,
  AiOutlineFileImage,
  AiOutlineFileWord,
  AiOutlineForm,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import ReactLoading from "react-loading";
import Sheet from "react-modal-sheet";
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactFlow, {
  Position,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import { v4 as uuid } from "uuid";
import BiDirectionalEdge from "./components/BiDirectionalEdge";
import ButtonEdge from "./components/ButtonEdge";
import ItemType from "./components/ItemType";
import ItemTypeText from "./components/ItemTypeText";
import ModalFolder from "./components/ModalFolder";
import SelfConnectingEdge from "./components/SelfConnectingEdge";
import SketchColor from "./components/SketchColor";
import { Upload } from "./components/Upload";
import Rectangular from "./components/shape/Rectangular";
import { setEdge, setNode } from "./helpers/storage";
import "./index.css";
import apiClient from "./services/apiClient";

const metadataDef = {
  type: "INP", // IMG , INP, IMGMZ
  typeText: "", // B ,I, S, T
  color: "black",
  displayColorPicker: false,
  typeAlign: "center", // L, C, R
  img: [],
  imgLib: "",
};

const initialEdges = [];
const TouchDeviceFlow = () => {
  const [queryParameters] = useSearchParams();
  const [step, setStep] = useState(2); // 1 = Create, 2 = cập nhật
  const [isLoading, setIsLoading] = useState(false); // 1 = Create, 2 = cập nhật
  const [values, setValues] = useState(metadataDef);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [nodeCustom, setNodeCustom] = useState([]);
  const [editNode, setEditNode] = useNodesState(null);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFolder, setIsOpenFolder] = useState(false);
  const [nameNode, setNameNode] = useState("");
  const onConnect = useCallback((connection) => {
    setEdges((eds) => addEdge({ ...connection, type: "buttonedge" }, eds));
  }, []);
  const [folder, setFolder] = useState([]);

  const userId = queryParameters.get("userId");
  const id = queryParameters.get("id");
  const uuidMindMap = queryParameters.get("uuid");

  const getMindmapDetail = async () => {
    const rest = await apiClient.post(
      "api/user-mind-map/detail",
      {
        uuid: uuidMindMap || "",
      }
      // {
      //   headers: {
      //     uuid: '6e822e7b-e672-4bff-97b9-1480ed118453s',
      //     user_id: 1
      //   }
      // }
    );
    const data = rest?.data?.data;

    if (data) {
      const node = data?.data_node ? CircularJSON.parse(data?.data_node) : [];
      const edge = data?.data_edge ? CircularJSON.parse(data?.data_edge) : [];
      setNodes(node);
      setEdges(edge);
    }
  };

  const edgeTypes = {
    bidirectional: BiDirectionalEdge,
    selfconnecting: SelfConnectingEdge,
    buttonedge: ButtonEdge,
  };

  useEffect(() => {
    if (uuidMindMap) {
      getMindmapDetail();
    }
  }, [uuidMindMap]);

  const onSubmit = () => {
    if (!isLoading) {
      if (editNode?.id && step === 2) {
        const nodeList = [...nodes];
        const index = nodeList.findIndex((i) => i.id == editNode?.id);
        if (index > -1) {
          nodeList[index]["data"]["label"] = nameNode;
          nodeList[index]["metadata"] = values;
        }
        setNodes([...nodeList]);
        resetForm();
        return;
      }

      const uid = uuid();
      setNodes([
        ...nodes,
        {
          id: uid,
          data: {
            label: nameNode,
          },
          position: {
            x: 50 + Math.floor(Math.random() * 100),
            y: 100 + Math.floor(Math.random() * 90),
          },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          metadata: {
            ...values,
          },
        },
      ]);

      setEdges((eds) =>
        addEdge(
          {
            source: editNode?.id,
            sourceHandle: null,
            target: uid,
            targetHandle: null,
            type: "buttonedge",
            animated: true,
            data: {
              numbericIndex: 1,
            },
          },
          eds
        )
      );
      resetForm();
    }
  };

  const resetForm = () => {
    setIsOpen(false);
    setEditNode(null);
    setNameNode("");
    setStep(2);
    setValues(metadataDef);
  };

  const onDeleteNode = (idNode) => {
    if (nodes?.length > 1) {
      setNodes((nds) => nodes.filter((node) => node.id !== idNode));
    } else {
      setNodes([]);
    }
    resetForm();
  };

  const saveNode = async () => {
    resetForm();
    if (userId) {
      setNode(CircularJSON.stringify(nodes));
      setEdge(CircularJSON.stringify(edges));
      if (uuidMindMap && id) {
        const res = await apiClient.post(
          "/api/user-mind-map/update",
          {
            data_node: CircularJSON.stringify(nodes),
            data_edge: CircularJSON.stringify(edges),
            id: id,
          },
          {
            headers: {
              uuid: uuidMindMap,
              user_id: userId,
            },
          }
        );

        if (res?.data) {
          toast.success("Cập nhật thành công");
          return;
        }
        toast.error("Lỗi");
        return;
      }
      const res = await apiClient.post(
        "/api/user-mind-map/create",
        {
          data_node: CircularJSON.stringify(nodes),
          data_edge: CircularJSON.stringify(edges),
        },
        {
          headers: {
            user_id: userId,
          },
        }
      );
      if (res?.data) {
        toast.success("Cập nhật thành công");
        return;
      }
      toast.error("Lỗi");
      return;
    }
    toast.error("Thiếu user id");
  };

  useEffect(() => {
    if (nodes && nodes?.length) {
      const nod = nodes.map((item) => {
        return {
          ...item,
          data: {
            label: (
              <Rectangular
                item={item}
                setNameNode={setNameNode}
                setEditNode={setEditNode}
                setValues={setValues}
                setIsOpen={setIsOpen}
              />
              // <div
              //   onClick={() => {
              //     setNameNode(item?.data?.label);
              //     setEditNode(item);
              //     setValues({ ...item?.metadata });
              //     setIsOpen(true);
              //   }}
              // >
              //   {item?.metadata?.type === "INP" ? (
              //     <div
              //       style={{
              //         fontSize: "10px",
              //         alignItems: "center",
              //         color: item?.metadata?.color
              //           ? item?.metadata?.color
              //           : "black",
              //         textAlign: item?.metadata?.typeAlign
              //           ? item?.metadata?.typeAlign
              //           : "center",
              //         fontWeight:
              //           item?.metadata?.typeText === "B" ? "700" : "400",
              //         textDecoration:
              //           item?.metadata?.typeText === "U"
              //             ? "line-through"
              //             : "unset",
              //         fontStyle:
              //           item?.metadata?.typeText === "I" ? "italic" : "unset",
              //       }}
              //     >
              //       {item.data.label}
              //     </div>
              //   ) : (
              //     <div
              //       style={{
              //         display: "flex",
              //         justifyContent: "center",
              //         alignItems: "center",
              //       }}
              //     >
              //       <div style={{ width: 50, height: 50 }}>
              //         <img
              //           src={item?.metadata?.img[0]?.data_url}
              //           style={{ maxWidth: "100%", maxHeight: "100%" }}
              //         />
              //       </div>
              //     </div>
              //   )}
              // </div>
            ),
          },
        };
      });
      setNodeCustom(nod);
    } else {
      setNodeCustom([]);
    }

    let handleLeft = document.querySelectorAll(".react-flow__handle-left");
    let handleRight = document.querySelectorAll(".react-flow__handle-right");
    if (handleLeft?.length) {
      handleLeft.forEach((element) => {
        if (!element?.innerHTML) {
          element.innerHTML += "I";
        }
      });
    }
    if (handleRight?.length) {
      handleRight.forEach((element) => {
        if (!element?.innerHTML) {
          element.innerHTML += "O";
        }
      });
    }
  }, [nodes]);

  const chooseImage = async (parentId) => {
    setValues({ ...values, type: "IMGMZ" });
    const res = await apiClient.post(
      "/api/folders/list",
      {
        type: "1",
        // id: "97",
      },
      {
        headers: {
          user_id: "1",
        },
      }
    );

    if (res?.data?.data) {
      setFolder(res?.data?.data);
      setIsOpenFolder(true);
    }
  };

  const choseImageLib = (imgItem) => {
    setValues({
      ...values,
      imgLib: imgItem?.image_url,
    });

    setIsOpenFolder(false);
  };
 

  


  return (
    <>
      <div style={{ padding: 5 }}>
        <div
          style={{
            background: "#4DAC62",
            width: "100px",
            color: "white",
            padding: 5,
            borderRadius: 8,
            textAlign: "center",
          }}
          onClick={() => {
            if (nodeCustom?.length) {
              saveNode();
              return;
            }
            setIsOpen(true);
          }}
        >
          {nodeCustom?.length ? "Cập nhật" : "Thêm mới"}
        </div>
      </div>

      {nodeCustom?.length ? (
        <ReactFlow
          nodes={nodeCustom}
          edges={edges}
          onConnect={onConnect}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          className="touchdevice-flow"
          edgeTypes={edgeTypes}
          fitView
          maxZoom={50}
        />
      ) : null}

      <Sheet
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        detent="content-height"
      >
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div style={{ paddingTop: 0 }}>
              {editNode?.id ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px 0",
                    borderBottom: "2px solid #DEDEDE",
                  }}
                >
                  <ItemType
                    icon={() => (
                      <AiOutlinePlusCircle
                        style={{ marginRight: "10px" }}
                        color={step === 1 ? "white" : "black"}
                      />
                    )}
                    onClick={() => {
                      setNameNode("");
                      setStep(1);
                    }}
                    isActive={step === 1 ? true : false}
                    name="Thêm"
                  />

                  <ItemType
                    icon={() => (
                      <AiOutlineForm
                        color={step === 2 ? "white" : "black"}
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    onClick={() => {
                      setNameNode(editNode?.data?.label);
                      setStep(2);
                    }}
                    isActive={step === 2 ? true : false}
                    name="Sửa"
                  />

                  <ItemType
                    icon={() => (
                      <AiOutlineDelete
                        color={"red"}
                        style={{ marginRight: "10px" }}
                      />
                    )}
                    onClick={() => {
                      setValues({ ...values, isAdd: false });
                      onDeleteNode(editNode.id);
                    }}
                    name="Xóa"
                  />
                </div>
              ) : null}

              <div
                style={{
                  padding: 16,
                  paddingTop: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px 0",
                  }}
                >
                  <ItemType
                    icon={() => (
                      <AiOutlineFileWord
                        color={values?.type === "INP" ? "white" : "black"}
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    onClick={() => {
                      setValues({ ...values, type: "INP" })
                      setIsOpenFolder(false)
                    } }
                    isActive={values?.type === "INP" ? true : false}
                    name={"Nội dung"}
                  />
                  <ItemType
                    icon={() => (
                      <AiOutlineFileImage
                        color={values?.type === "IMG" ? "white" : "black"}
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    onClick={() => {
                      setValues({ ...values, type: "IMG" })
                      setIsOpenFolder(false)
                    } }
                    isActive={values?.type === "IMG" ? true : false}
                    name={"Upload Ảnh"}
                  />
                  <ItemType
                    icon={() => (
                      <AiOutlineFileImage
                        color={values?.type === "IMGMZ" ? "white" : "black"}
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    onClick={chooseImage}
                    isActive={values?.type === "IMGMZ" ? true : false}
                    name={"Med icon"}
                  />
                </div>

                {values?.type == "INP" ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      placeholder="Tiêu đề"
                      value={nameNode}
                      style={{
                        height: "32px",
                        fontSize: "12px",
                        borderRadius: "5px",
                        border: "1px solid #DEDEDE",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        margin: "6px",
                        width: "100%",
                      }}
                      type="text"
                      onChange={(val) => {
                        setNameNode(val.target.value);
                      }}
                    />
                  </div>
                ) : null}
                {values?.type == "IMG" ? (
                  <div>
                    {isLoading ? (
                      <div
                        style={{
                          left: "50%",
                          top: "40%",
                          position: "absolute",
                          transform: "translate(-50%, 0)",
                        }}
                      >
                        <ReactLoading
                          type={"spinningBubbles"}
                          color="#4DAC62"
                        />
                      </div>
                    ) : null}

                    <Upload
                      values={values}
                      setValues={setValues}
                      setIsLoading={setIsLoading}
                    />
                  </div>
                ) : null}

                {values?.type == "IMGMZ" ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      src={values?.imgLib}
                      style={{ width: 100, height: 100, objectFit: "cover" }}
                    />
                  </div>
                ) : null}
                {values?.type == "INP" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px 0",
                      }}
                    >
                      <ItemTypeText
                        name={"B"}
                        onClick={() => setValues({ ...values, typeText: "B" })}
                        isActive={values?.typeText === "B" ? true : false}
                        styleCustom={{ fontWeight: "700" }}
                      />
                      <ItemTypeText
                        name={"I"}
                        onClick={() => setValues({ ...values, typeText: "I" })}
                        isActive={values?.typeText === "I" ? true : false}
                        styleCustom={{ fontStyle: "italic" }}
                      />
                      <ItemTypeText
                        name={"S"}
                        onClick={() => setValues({ ...values, typeText: "S" })}
                        isActive={values?.typeText === "S" ? true : false}
                        styleCustom={{ textDecoration: "line-through" }}
                      />
                      {/* <ItemTypeText
                  name={"T"}
                  onClick={() => setValues({ ...values, typeText: "T" })}
                  isActive={values?.typeText === "T" ? true : false}
                /> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #DEDEDE",
                        borderTop: "1px solid #DEDEDE",
                        padding: "10px 6px",
                      }}
                    >
                      <div>Color</div>
                      <div>
                        <SketchColor value={values} setValue={setValues} />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px 0",
                      }}
                    >
                      <ItemType
                        icon={() => (
                          <AiOutlineAlignLeft
                            color={
                              values?.typeAlign === "left" ? "white" : "black"
                            }
                          />
                        )}
                        onClick={() =>
                          setValues({ ...values, typeAlign: "left" })
                        }
                        isActive={values?.typeAlign === "left" ? true : false}
                      />
                      <ItemType
                        icon={() => (
                          <AiOutlineAlignCenter
                            color={
                              values?.typeAlign === "center" ? "white" : "black"
                            }
                          />
                        )}
                        onClick={() =>
                          setValues({ ...values, typeAlign: "center" })
                        }
                        isActive={values?.typeAlign === "center" ? true : false}
                      />
                      <ItemType
                        icon={() => (
                          <AiOutlineAlignRight
                            color={
                              values?.typeAlign === "right" ? "white" : "black"
                            }
                          />
                        )}
                        onClick={() =>
                          setValues({ ...values, typeAlign: "right" })
                        }
                        isActive={values?.typeAlign === "right" ? true : false}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <div
              style={{
                padding: "10px",
                textAlign: "center",
                background: isLoading ? "#DEDEDE" : "#4DAC62",
                color: "white",
              }}
              onClick={onSubmit}
            >
              {editNode?.id ? "Cập nhật" : "Thêm mới"}
            </div>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
      <ToastContainer />

      <ModalFolder
        isOpen={isOpenFolder}
        setIsOpen={setIsOpenFolder}
        setFolder={setFolder}
        data={folder}
        onOpenFolder={chooseImage}
        choseImageLib={choseImageLib}
       />
    </>
  );
};

export default TouchDeviceFlow;
