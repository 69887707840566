import React from "react";

export default function Rectangular({
  item,
  setNameNode,
  setEditNode,
  setValues,
  setIsOpen,
}) {
  return (
    <div
      onClick={() => {
        setNameNode(item?.data?.label);
        setEditNode(item);
        setValues({ ...item?.metadata });
        setIsOpen(true);
      }}
    >
      {item?.metadata?.type === "INP" ? (
        <div
          style={{
            fontSize: "10px",
            alignItems: "center",
            color: item?.metadata?.color ? item?.metadata?.color : "black",
            textAlign: item?.metadata?.typeAlign
              ? item?.metadata?.typeAlign
              : "center",
            fontWeight: item?.metadata?.typeText === "B" ? "700" : "400",
            textDecoration:
              item?.metadata?.typeText === "U" ? "line-through" : "unset",
            fontStyle: item?.metadata?.typeText === "I" ? "italic" : "unset",
          }}
        >
          {item.data.label}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: 50, height: 50 }}>
            <img
              src={
                item?.metadata?.type === "IMG"
                  ? item?.metadata?.img[0]?.data_url
                  : item?.metadata?.imgLib
              }
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
